// src/utils/decimalFormatter.js
import BigNumber from "bignumber.js";

export function formatDecimal(value, fixedDecimalPlaces = 5) {
  const num = new BigNumber(value);

  // Format to the fixed number of decimal places
  const formattedValue = num.toFixed(fixedDecimalPlaces);

  // Remove unnecessary trailing zeros and the decimal point if there are no decimal places
  const trimmedValue = formattedValue
    .replace(/(\.\d*?[1-9])0+$/, "$1")
    .replace(/\.0*$/, "");

  return trimmedValue;
}

// export function formatDecimal(value, fixedDecimalPlaces = null) {
//   const num = new BigNumber(value);

//   // Handle cases where fixedDecimalPlaces is provided
//   if (fixedDecimalPlaces !== null) {
//     return num.toFixed(fixedDecimalPlaces);
//   }

//   // Check if the value is a whole number
//   if (num.isInteger()) {
//     return num.toFixed(0);
//   }

//   // Find the number of decimal places required and remove trailing zeros
//   const formattedValue = num.toFixed().replace(/(\.[0-9]*[1-9])0+|\.0*$/, "$1");
//   return formattedValue;
// }

// Function to get the number of decimal places
const getDecimalPlaces = (num) => {
  const parts = num.toString().split(".");
  return parts.length > 1 ? parts[1].length : 0;
};

// Function to format the decimal based on the entry price's decimal places
export function formatTakeProfit(entryPrice, takeProfit) {
  const decimalPlaces = getDecimalPlaces(entryPrice);
  const num = new BigNumber(takeProfit);
  return num.toFixed(decimalPlaces).replace(/(\.[0-9]*[1-9])0+|\.0*$/, "$1");
}
