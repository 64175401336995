import React from "react";
import BigNumber from "bignumber.js";
import { formatDecimal } from "../../util/decimalFormatter";

const UserTicker = (props, spread) => (
  <ul>
    <li>
      <h4 className="ng-binding">{props.translate("Balance")}</h4>
      <strong className="ng-binding">
        {isNaN(props.balance) ? (
          <strong className="ng-binding">{"0.00"}</strong>
        ) : (
          <strong className="ng-binding">
            {props.balance ? `$${formatDecimal(props.balance, 2)}` : "0.00"}
          </strong>
        )}
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">{props.translate("Equity")}</h4>
      <strong className="ng-binding">
        {isNaN(props.equityPrice) ? (
          <strong className="ng-binding">{"0.00"}</strong>
        ) : (
          <strong className="ng-binding">
            {props.equityPrice
              ? `$${formatDecimal(props.equityPrice, 2)}`
              : "0.00"}
          </strong>
        )}
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">{props.translate("Leverage")}</h4>
      {isNaN(props.leverage) ? (
        <strong className="changeRate ng-binding ng-scope green">
          {"1:1"}
        </strong>
      ) : (
        <strong className="changeRate ng-binding ng-scope green">
          {props.leverage ? `1:${props.leverage}` : "1:1"}
        </strong>
      )}
    </li>
    <li>
      <h4 className="ng-binding">{props.translate("Used Margin")}</h4>
      <strong className="ng-binding">
        {props.usedMargin ? `$${props.usedMargin}` : "0.00"}
      </strong>
    </li>
    <li>
      <h4 className="ng-binding">{props.translate("Available Margin")}</h4>
      {isNaN(props.freeMargin) ? (
        <strong className="ng-binding">{"0.00"}%</strong>
      ) : (
        <strong className="ng-binding">
          {props.freeMargin ? `$${props.freeMargin}` : "0.00"}
        </strong>
      )}
    </li>
    <li>
      <h4 className="ng-binding">{props.translate("Profit")}</h4>
      <strong
        className="ng-binding"
        style={props.profit < 0 ? { color: "#f23345" } : { color: "#089981" }}
      >
        {isNaN(props.profit) ? "0.0" : `$${formatDecimal(props.profit, 5)}`}
      </strong>
    </li>
  </ul>
);
export default UserTicker;
